import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const TrainingWorkshop = () => {
return (
<>
<section className="page-header">
   <div className="page-header-bg"></div>
   <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
   <div className="container">
      <div className="page-header__inner">
         <ul className="thm-breadcrumb list-unstyled ml-0">
            <li>
               <Link to="/">
               Home</Link>
            </li>
            <li> Training & Workshop   </li>
         </ul>
         <h2>Training & Workshop  </h2>
      </div>
   </div>
</section>
<section className="innerbody">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <div className="accordion achievementacc" id="accordionExample">
            <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseposcocpo" aria-expanded="false" aria-controls="collapseposcocpo">
                        POCSO WORKSHOP for CPOs at Kolkata: 21 to 23 November 2024
                        </button>
                     </div>
                     <div id="collapseposcocpo" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>A three-day workshop on POCSO was held from 21 to 23 November 2024 at Loreto House, Kolkata. The Child Protection Officers, Mrs. Shalita Deborah Roychowdhury and Mrs. Sheela Jamesina attended the workshop and represented Loreto Convent School, Delhi Cantt. They were accompanied by Mrs Nella Davis, the National Coordinator of the Child Protection Team</p>
                              <p>The session was very informative and guided the child protection officers of all the Loreto schools on the nuances of the POCSO Act. The workshop was interactive and interspersed with activities for the CPOs to relate better to the various aspects of Child Protection and the identification of abuse.</p>
                              <p>At the end of the workshop, the group came up with an Action Plan for the current and the following academic sessions. The Child Protection Officers were awarded certificates at the end of the workshop.</p>
                              <br />
                              <div className="row">
                                 <div className="col-md-6">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/posco-cpo1.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                                 <div className="col-md-6">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/posco-cpo2.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>


            <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetech" aria-expanded="false" aria-controls="collapsetech">
                        THE TECH 2024
                        </button>
                     </div>
                     <div id="collapsetech" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>The AI seminar, held on 28th November 2024 at Vidyajyoti, Vikas Marg, was attended by Mrs Alice Rose D’Lima, Sr. Aruna, and Ms Ruby Ahuja, and commenced with a prayer service, followed by a welcome address by Prof. Dr. Rajakumar Joseph, Principal of Vidyajyoti. The event was graced by dignitaries including H.E. Javier Manuel Paulinich Velarde, Rev. Anil J.T. Couto, and Rev. Dr. Stainslaus D’Souza, SJ, adding prestige to the occasion.</p>
                              <p>The seminar introduced participants to the fundamentals of Artificial Intelligence (AI), including its history, subfields like Machine Learning, and applications in healthcare, finance, and education. It also highlighted the ethical use of AI, emphasizing its potential for positive or negative impacts depending on its application.</p>
                              <p>A seminar on ChatGPT and similar tools explored how AI processed data and addressed its role in job creation and displacement. The discussions underlined the importance of responsible and wise use of AI.</p>
                              <p>Participants provided overwhelmingly positive feedback, appreciating the engaging and thought-provoking sessions. The seminar inspired attendees to explore AI further while promoting ethical technology use.</p>
                              <br />
                              <div className="row">
                                 <div className="col-md-12">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/the-tech.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>






            <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsemary" aria-expanded="false" aria-controls="collapsemary">
                        FRIENDS OF MARY WARD MEETING 2024
                        </button>
                     </div>
                     <div id="collapsemary" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>On 9 and 10 November 2024, Mrs. Rashmi, TGT attended the two-day meeting of Friends of Mary Ward at St. Mary's Convent College, Nainital. The main objective was to complete and carry forward the efforts, values and work done by Venerable Mary Ward. The participating schools presented the work undertaken in their school on Mary Ward's values and maxims.</p>
                              <br />
                              <div className="row">
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/mary1.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/mary2.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/mary3.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdolesence" aria-expanded="false" aria-controls="collapseAdolesence">
                        DEALING WITH ADOLESCENCE 
                        </button>
                     </div>
                     <div id="collapseAdolesence" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p><b>Date Of The Workshop:</b> 3 & 04 October 2024</p>
                              <p><b>Venue:</b> Loreto Provincialate, Kolkata</p>
                              <p>Mrs. Ani Parameswaran and Mrs. Manju Kumar represented Loreto Convent, Delhi Cantt. in Kolkata for a two-day workshop. The purpose was to discuss the different facets of adolescents that teachers encounter with them. It was indeed a stimulating session that would help the teachers devise solutions and work more skillfully with the adolescents.</p>
                              <br />
                              <div className="row">
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/adolenscence-1.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/adolenscence-2.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                        Pedagogical processes for Foundational Learning and Numeracy
                        </button>
                     </div>
                     <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p> On July 4th and 5th, 2024, Loreto Provincialate, Kolkata hosted an informative workshop for educators on "Pedagogical processes for Foundational Learning and Numeracy." The event was attended by Ms. Jaisika Sabharwal and Ms. Jennifer Massey from Loreto Convent School, Delhi Cantt. The workshop aimed to empower teachers with effective strategies and tools to enhance students' foundational learning and numeracy skills. Ms. Nidhi Srivastava, a teacher from Loreto Intermediate College, Lucknow, served as the resource person, enriching attendees' understanding and improving their teaching methods.</p>
                              <br/>
                              <div className="row">
                                 <div className="col-md-12">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Pedagogical-processes.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                                 </div>
                                 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                        SCHOOL CATECHISM TEACHERS’ SEMINAR
                        </button>
                     </div>
                     <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p> On 29 April 2024, Ms. Shaly Francis and Ms. Marina Dunn from Loreto Convent School, Delhi Cantt. attended Catechism Teachers’ Seminar in the Archdiocese of Delhi. The resource person was Fr. Vijay Machado, Secretary to the Commission for Catechetics of CCBI.</p>
                              <p>Fr. Manikya Raju Stanislaus, the Director of Religious Education at Vishwas-Kiran Diocesan Catechetical Center, Archdiocese of Delhi introduced Fr. Vijay Machado.</p>
                              <p>Fr. Vijay started the seminar with a prayer invoking God’s blessings. </p>
                              <p>In the beginning, Fr. Vijay introduced a few books related to Catechesis such as YOUCAT, DOCAT, CHRIST IS ALIVE, etc. </p>
                              <p>The teachers were enlightened on some general questions such as where do we catechise? – anywhere in the world, why do we catechize? - to deepen one’s faith in one’s own living situation and whom do we catechize? - a human person, living in a concrete situation.</p>
                              <p>Topics such as Youth Catechesis- YOUCAT Ministry and Study, preparing Catechism syllabus in the beginning of the year, how to prepare lesson plans, preparation of notes before teaching any lesson, immediate preparation, Aim- Jesus Christ alone reveals the ultimate meaning of our experiences and how Christ reveals the faith dimension of our life, purpose of Catechesis is to facilitate a response, points to keep in mind while planning Catechism lessons, three aspects to be given attention while teaching Catechism, Media in Catechesis (low-cost media), etc. were briefly covered with the help of a PowerPoint Presentation. </p>
                              <p>Towards the end, some practical tips were shared with the teachers such as to emphasize on the use of low-cost media (without electricity): Photo language, Newspaper articles, Gospel Song, Modern Psalm, Letters to God/Email/SMS/WhatsApp and Pilgrimage/Different places.</p>
                              <p>The seminar ended by Fr. Vijay Machado urging the teachers to put into use what was shared during the informative session.</p>
                              <br/>
                              <div className="row">
                                 <div className="col-md-4">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/CATECHISM1.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                                 </div>
                                 <div className="col-md-4">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/CATECHISM2.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                                 </div>
                                 <div className="col-md-4">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/CATECHISM3.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                                 </div>
                                 <div className="col-md-4">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/CATECHISM4.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                                 </div>
                                 <div className="col-md-4">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/CATECHISM5.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                        National Progressive School Conference
                        </button>
                     </div>
                     <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p> The NPSC (National Progressive School Conference) had organised a workshop on ‘Beyond Teaching’ on 27 April 2024 at Ahlcon International School, Mayur Vihar. The workshop primarily focussed on the topic ‘Living in Excellence’ for the teachers of the NPSC member schools in collaboration with Chinmaya Mission, Noida. The workshop was attended by Mrs. Suman Sharma.
                              </p>
                              <br/>
                              <center>
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/npsc.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                              </center>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                        READING MISSION
                        </button>
                     </div>
                     <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>  The CBSE had organised a workshop on the Science of Reading on 05 April 2024 at Bal Bhawan Public School, Mayur Vihar,Delhi. The workshop primarily focussed on the Reading Mission undertaken by the CBSE to attempt Guinness World Record for ‘the most children reading at one time’ on National Reading Day. The workshop was attended by the school Principal, Mrs. Alice Rose D’Lima and the Head of the Department of English, Mrs. Rekha Jacob.
                              </p>
                              <br/>
                              <center>
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/readingmission.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                              </center>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        CAPACITY BUILDING PROGRAM XII PHYSICS
                        </button>
                     </div>
                     <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>A Capacity building workshop was organized on 19 and 20 September 2019 for XII PHYSICS
                                 It was very subject enriching and interesting program.
                              </p>
                              <p>The resource persons were</p>
                              <p>1. Dr. Rajeev Tyagi Principal Mount Carmel school dwarka</p>
                              <p>2. Pragya Nopani well known physics teacher at Anveshika</p>
                              <p>I (Mr. Ajay Kumar) will say thank you to my Principal Sr Monica for giving me this opportunity.</p>
                              <br/>
                              <center>
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/PROGRAMXIIPHYSICS.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>    
                              </center>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Capacity building program for Science
                        </button>
                     </div>
                     <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>A Capacity building workshop for Science of class X was organized on 20 and 21 September at Sant Nirankari school.Mrs Rupinder Oberoi from Loreto Convent School attended this two day workshop.The two resource speakers Mrs Veena Mishra -Principal National Victor School and Mr Shubham Chakarborty demonstrated such interesting activities for effective teaching of science in class X.
                                 The workshop was so interesting and informative that it kept all the teachers totally involved for two days.
                                 The new pattern of CBSE assessment and evaluation of class X was also discussed.It was truly an enriching  experience.
                              </p>
                              <br/>
                              <center>
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/capacity-building.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                              </center>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        CBSE capacity building workshop psychology 
                        </button>
                     </div>
                     <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>On 30th and 31st July,I Risa Joseph attended a workshop held by CBSE related to class 11 and 12 Psychology. the workshop was 
                                 held at Sri venkatashwara school sector 18 Dwarka. The resource person for the workshop was Mrs Usha Anand, She is also one 
                                 of the author's for the NCERT textbook  Psychology for classes 11 and 12.The main points discussed during the workshop was 
                                 regarding practical work, assessment, changes in chapter 'Psychological disorder' and how to make topics more interesting.it 
                                 was a learning experience for me.
                              </p>
                              <br />
                              <div className="row">
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/psychology-1.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/psychology-2.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        ENGLISH CBP XII
                        </button>
                     </div>
                     <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <h1 className="head1">Capacity Building Programme</h1>
                              <p>A workshop was conducted by CBSE, Centre of Excellence on 19 and 20 July 2019 at Ambience Public School, Safdarjung Enclave. 
                                 The resource persons were Mr. Derek Richard Brendish, Principal of Apex Public School and Mrs. Soumya Gulati, Deputy Director 
                                 of Bluebells Group of Schools.
                              </p>
                              <p>It was a Capacity Building Programme (CBP) for teachers teaching English in classes XI and XII, based on the revised syllabus of
                                 English (core). The aim of the workshop was to familiarise the teachers with the additional chapters in the Literature section 
                                 and the   inclusion of ASL as Practical Exam for Class XII. 
                              </p>
                              <p>The workshop provided a platform for professional development as well as helped in making English language learning more effective and 
                                 conducive for the learners with the help of various strategies.
                              </p>
                              <p>Ms.Bindu Jaggi and Ms.Rekha Jacob attended the workshop</p>
                              <br/>
                              <center>
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Capacity-Building-2.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                              </center>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        MATHS
                        </button>
                     </div>
                     <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>Ms. Leena Job Mathew and Ms. Purnima Bhatti attended a Mathematics Workshop on Teaching Mathematics through Lab Activities, conducted 
                                 at MBS International Schoo ,Dwarka on 20 July 2019. The main speaker for the work shop was Dr. Hukum Singh from NCERT. He explained 
                                 the necessity and importance of hands on experience in teaching Mathematics. It was an enriching experience.The teachers were awarded 
                                 a Certificate for participation.
                              </p>
                              <br />
                              <div className="row">
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/math-1.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                                    </center>
                                 </div>
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/math-2.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>   
                                    </center>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        The CBSE Capacity Building Programme for Hindi teachers
                        </button>
                     </div>
                     <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>The CBSE Capacity Building Programme for Hindi teachers was organised at St. Francis de Sales Senior Secondary School, Janakpuri, New Delhi. The two days Workshop Programme(23-24 July 2019) provided platform for teachers to upgrade their pedagogical skills and use latest technology tools to make learning process more realistic, stress free and joyful.</p>
                              <br />
                              <div className="row">
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/CBSE-Capacity-1.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                                 <div className="col-md-4">
                                    <center>
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/CBSE-Capacity-2.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>  
                                    </center>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                     <div className="card-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        Khelo India
                        </button>
                     </div>
                     <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="card-body">
                           <div className="workk">
                              <p>I Mrs. SNEHLATA AGGARWAL attended Khelo India school fitness assessment program conducted by cbse in association with Sports Authority of India on22nd june 2019 held at Red Roses Public School, Palam Vihar,s Gurugram. Khelo India is a National programme for development of sports in India,was introduced in December 2017 by the Ministry of Youth Affairs and sports, Government of India to revive sports culture in India at grass roots level. All the school are directed by CBSE to conduct fitness tests from classes 1 to 12.</p>
                              <br />
                              <center>
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/KheloIndia.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad> 
                              </center>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</>
)
}
export default TrainingWorkshop