import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'lightbox.js-react/dist/index.css'
import Header from "./Component/Header";
import Footer from "./Component/Footer"; 
import OurSchool from "./Pages/OurSchool";
import LegendaryPast from "./Pages/LegendaryPast";  
import JourneyLoreto from "./Pages/JourneyLoreto";  
import OurFounder from "./Pages/OurFounder";  
import SchoolMotto from "./Pages/SchoolMotto";  
import PrincipalMessage from "./Pages/PrincipalMessage";  
import MissionStatement from "./Pages/MissionStatement";  
import LoretoSchoolsInIndia from "./Pages/LoretoSchoolsInIndia";  
import Infrastructure from "./Pages/Infrastructure";  
import HealthPhysicalEdu from "./Pages/HealthPhysicalEdu";  
import Circulars from "./Pages/Circulars";  
import Syllabus from "./Pages/Syllabus";  
import ReadingBooks from "./Pages/ReadingBooks";  
import MaryWard from "./Pages/MaryWard";  
import CompanionMaryWard from "./Pages/CompanionMaryWard";  
import PaintedLife from "./Pages/PaintedLife";  
import Maxims from "./Pages/Maxims";  
import MaryWardsStamp from "./Pages/MaryWardsStamp";  
import OurSpirituality from "./Pages/OurSpirituality";  
import ListofSubject from "./Pages/ListofSubject";  
import AssessmentDesign from "./Pages/AssessmentDesign";  
import OfficeHours from "./Pages/OfficeHours";  
import SchoolHours from "./Pages/SchoolHours";  
import HowtoLearn from "./Pages/HowtoLearn";  
import ConductDiscipline from "./Pages/ConductDiscipline";  
import VacationLeave from "./Pages/VacationLeave";  
import SchoolFee from "./Pages/SchoolFee";  
import Uniform from "./Pages/Uniform";  
import Policy from "./Pages/Policy";  
import BusRules from "./Pages/BusRules"; 
import ClassStrength from "./Pages/ClassStrength"; 
import SchoolReport from "./Pages/SchoolReport"; 
import AdmissionWithdrawal from "./Pages/AdmissionWithdrawal"; 
import EvaluationAssessment from "./Pages/EvaluationAssessment"; 
import Gallery from "./Pages/Gallery"; 
import ClassPhotographs from "./Pages/ClassPhotographs"; 
import Emagazine from "./Pages/e-magazine"; 
import Newsletter from "./Pages/Newsletter"; 
import News from "./Pages/News"; 
import Achievements from "./Pages/Achievements"; 
import AchievementDetails from "./Pages/AchievementDetails"; 
import TopperX from "./Pages/TopperX"; 
import TopperXII from "./Pages/TopperXII"; 
import AllRoundExcellence from "./Pages/AllRoundExcellence"; 
import AryabhataGanitChallenge from "./Pages/AryabhataGanitChallenge"; 
import ManagementDetails from "./Pages/ManagementDetails"; 
import LoretoDelhiCommunity from "./Pages/LoretoDelhiCommunity"; 
import OurPrincipals from "./Pages/OurPrincipals"; 
import StudentCouncil from "./Pages/StudentCouncil"; 
import OfficeStaff from "./Pages/OfficeStaff"; 
import TeachingStaff from "./Pages/TeachingStaff"; 
import HeadGirls from "./Pages/HeadGirls"; 
import HeadGirlMessage from "./Pages/HeadGirlMessage"; 
import CyberWarrior from "./Pages/CyberWarrior"; 
import VisitAshalayam from "./Pages/VisitAshalayam"; 
import EarthquakeTurkish from "./Pages/EarthquakeTurkish"; 
import ContactUs from "./Pages/ContactUs"; 
import Home from "./Pages/Home";
import TrainingWorkshop from'./Pages/TrainingWorkshop'
import RecentActivity from'./Pages/RecentActivity'
import SubActivity from'./Pages/SubActivity'
import Pphelp from'./Pages/Pphelp'
import PanLoretoCelebration from'./Pages/PanLoretoCelebration'
import ErpSoftware from'./Pages/ErpSoftware'
import CBSEDisclosure from'./Pages/CBSEDisclosure'
import VideoGallery from'./Pages/VideoGallery'
import Tc from'./Pages/Tc'
import Events from "./Pages/Events"; 
import KidsCorner from "./Pages/KidsCorner"; 
import JpicGallery from "./Pages/JpicGallery"; 
import JpicPage from "./Pages/JpicPage"; 
import SubGallery from "./Pages/SubGallery"; 
import SubCategoryKindergarten from "./Pages/SubCategoryKindergarten"; 
import VisionStatement from "./Pages/VisionStatement"; 
import AanchalSpecialSchool from "./Pages/AanchalSpecialSchool"; 
import VisitAWWAASHA from "./Pages/visitAWWAASHA"; 
import ValueEducation from "./Pages/ValueEducation"; 
import VisitVidyaJyotiCollege from "./Pages/VisitVidyaJyotiCollege"; 

import PTA from "./Pages/PTA"; 

import ScrollTopBtn from'./Component/ScrollTopBtn'
import { BrowserRouter, Routes, Route } from "react-router-dom";




function App() {
  return (
      <>
      <BrowserRouter>
      <ScrollTopBtn/>
      <Header/>
          <Routes>
           <Route  path="/" element={<Home/>}/>
           <Route  path="OurSchool" element={<OurSchool/>}/>
           <Route  path="LegendaryPast" element={<LegendaryPast/>}/>
           <Route  path="JourneyLoreto" element={<JourneyLoreto/>}/>
           <Route  path="OurFounder" element={<OurFounder/>}/>
           <Route  path="SchoolMotto" element={<SchoolMotto/>}/>
           <Route  path="PrincipalMessage" element={<PrincipalMessage/>}/>
           <Route  path="MissionStatement" element={<MissionStatement/>}/>
           <Route  path="LoretoSchoolsInIndia" element={<LoretoSchoolsInIndia/>}/>
           <Route  path="Infrastructure" element={<Infrastructure/>}/>
           <Route  path="HealthPhysicalEdu" element={<HealthPhysicalEdu/>}/>
           <Route  path="Circulars" element={<Circulars/>}/>
           <Route  path="Syllabus" element={<Syllabus/>}/>
           <Route  path="ReadingBooks" element={<ReadingBooks/>}/>
           <Route  path="MaryWard" element={<MaryWard/>}/>
           <Route  path="CompanionMaryWard" element={<CompanionMaryWard/>}/>
           <Route  path="PaintedLife" element={<PaintedLife/>}/>
           <Route  path="Maxims" element={<Maxims/>}/>
           <Route  path="MaryWardsStamp" element={<MaryWardsStamp/>}/>
           <Route  path="OurSpirituality" element={<OurSpirituality/>}/>
           <Route  path="ListofSubject" element={<ListofSubject/>}/>
           <Route  path="AssessmentDesign" element={<AssessmentDesign/>}/>
           <Route  path="OfficeHours" element={<OfficeHours/>}/>
           <Route  path="SchoolHours" element={<SchoolHours/>}/>
           <Route  path="HowtoLearn" element={<HowtoLearn/>}/>
           <Route  path="ConductDiscipline" element={<ConductDiscipline/>}/>
           <Route  path="VacationLeave" element={<VacationLeave/>}/>
           <Route  path="SchoolFee" element={<SchoolFee/>}/>
           <Route  path="Uniform" element={<Uniform/>}/>
           <Route  path="Policy" element={<Policy/>}/>
           <Route  path="BusRules" element={<BusRules/>}/>
           <Route  path="ClassStrength" element={<ClassStrength/>}/>
           <Route  path="SchoolReport" element={<SchoolReport/>}/>
           <Route  path="AdmissionWithdrawal" element={<AdmissionWithdrawal/>}/>
           <Route  path="EvaluationAssessment" element={<EvaluationAssessment/>}/>
           <Route  path="Gallery" element={<Gallery/>}/>
           <Route  path="ClassPhotographs" element={<ClassPhotographs/>}/>
           <Route  path="e-magazine" element={<Emagazine/>}/>
           <Route  path="Newsletter" element={<Newsletter/>}/>
           <Route  path="News" element={<News/>}/>
           <Route  path="Achievements" element={<Achievements/>}/>
           <Route  path="AchievementDetails" element={<AchievementDetails/>}/>
           <Route  path="TopperX" element={<TopperX/>}/>
           <Route  path="TopperXII" element={<TopperXII/>}/>
           <Route  path="AllRoundExcellence" element={<AllRoundExcellence/>}/>
           <Route  path="AryabhataGanitChallenge" element={<AryabhataGanitChallenge/>}/>
           <Route  path="ManagementDetails" element={<ManagementDetails/>}/>
           <Route  path="LoretoDelhiCommunity" element={<LoretoDelhiCommunity/>}/>
           <Route  path="OurPrincipals" element={<OurPrincipals/>}/>
           <Route  path="StudentCouncil" element={<StudentCouncil/>}/>
           <Route  path="OfficeStaff" element={<OfficeStaff/>}/>
           <Route  path="TeachingStaff" element={<TeachingStaff/>}/>
           <Route  path="HeadGirls" element={<HeadGirls/>}/>
           <Route  path="HeadGirlMessage" element={<HeadGirlMessage/>}/>
           <Route  path="CyberWarrior" element={<CyberWarrior/>}/>
           <Route  path="HeadGirlMessage" element={<HeadGirlMessage/>}/>
           <Route  path="VisitAshalayam" element={<VisitAshalayam/>}/>
           <Route  path="EarthquakeTurkish" element={<EarthquakeTurkish/>}/>
           <Route  path="ContactUs" element={<ContactUs/>}/>
           <Route  path="TrainingWorkshop" element={<TrainingWorkshop/>}/>
           <Route  path="RecentActivity" element={<RecentActivity/>}/>
           <Route  path="SubActivity" element={<SubActivity/>}/>
           <Route  path="Pphelp" element={<Pphelp/>}/>
           <Route  path="PanLoretoCelebration" element={<PanLoretoCelebration/>}/>
           <Route  path="ErpSoftware" element={<ErpSoftware/>}/>
           <Route  path="CBSEDisclosure" element={<CBSEDisclosure/>}/>
           <Route  path="VideoGallery" element={<VideoGallery/>}/>
           <Route  path="Tc" element={<Tc/>}/>
           <Route  path="Events" element={<Events/>}/>
           <Route  path="KidsCorner" element={<KidsCorner/>}/>
           <Route  path="JpicGallery" element={<JpicGallery/>}/>
           <Route  path="JpicPage" element={<JpicPage/>}/>
           <Route  path="SubGallery" element={<SubGallery/>}/>
           <Route  path="SubCategoryKindergarten" element={<SubCategoryKindergarten/>}/>
           <Route  path="PTA" element={<PTA/>}/>
           <Route  path="VisionStatement" element={<VisionStatement/>}/>
           <Route  path="AanchalSpecialSchool" element={<AanchalSpecialSchool/>}/>
           <Route  path="visitAWWAASHA" element={<VisitAWWAASHA/>}/>
           <Route  path="ValueEducation" element={<ValueEducation/>}/>
           <Route  path="VisitVidyaJyotiCollege" element={<VisitVidyaJyotiCollege/>}/>
          </Routes>
       <Footer/>
     </BrowserRouter>
      </>
  )
}

export default App;
