import React from 'react'
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom'
const VisitVidyaJyotiCollege = () => {
    return (
        <>
            <section className="page-header">
                <div className="page-header-bg"></div>
                <div className="page-header-shape-1">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
                </div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li><Link to="/"> Home</Link></li>
                            <li> Outreach </li>
                            <li> Visit to Vidya Jyoti College of Theology </li>
                        </ul>
                        <h2>Visit to Vidya Jyoti College of Theology</h2>
                    </div>
                </div>
            </section>
            <section className="innerbody">
                <div className="container contact-page">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="head1">Visit to Vidya Jyoti College of Theology</h1>
                            <p>The students of class VIII C went for an outreach programme on 4 February 2025 to the Vidya Jyoti College of Theology. Students interacted and donated ration to the people from Majnu Ka Tila and Kashmir Youth Camp. It was an eye-opening and enriching trip that instilled many values in students.</p>
                        </div>
                    </div>
                    <div className="row align-images g-4">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/vidyaJyoti1.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/vidyaJyoti2.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/vidyaJyoti3.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/vidyaJyoti4.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/vidyaJyoti5.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/vidyaJyoti6.jpg" className="img-fluid" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VisitVidyaJyotiCollege